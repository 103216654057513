<script>
import ShareDocumentQuery from "@/graphql/queries/document_management/ShareDocuments.gql";
import DocumentsTableNameCellRenderer from "@/components/documents/documents_table/DocumentsTableNameCellRenderer.vue";
import DocumentsTableFileSizeCellRendererVue from "@/components/documents/documents_table/DocumentsTableFileSizeCellRenderer.vue";
import DocumentBreadcrumbs from "@/components/documents/documents_table/DocumentsTableBreadcrumb.vue";
import DocumentShareManager from "./DocumentShareManager";
import { isFolder } from "@/helpers/DocumentHelpers";
import { Components } from "manageplaces-ui-kit";
export default {
  extends: Components.BaseTable,
  apollo: {
    documents: {
      query: ShareDocumentQuery,
      variables() {
        return {
          id: this.$route.params.id,
          folder: this.folder
        };
      },
      update(data) {
        return data.shareDocuments;
      },
      result() {
        this.setRowData(this.documents);
        this.stopLoading();
      }
    }
  },
  data() {
    const vm = this;
    return {
      selection: "multiple",
      folder: "/",
      breadcrumbPath: "/",
      docManager: new DocumentShareManager(this.$route.params.id),
      config: {
        searchable: false,
        embedded: true
      },
      columns: [
        {
          colId: "checkbox",
          maxWidth: 50,
          checkboxSelection: true,
          suppressMenu: true,
          headerCheckboxSelection: true,
          resizable: false,
          sortable: false
        },
        {
          // checkboxSelection: true,
          // headerCheckboxSelection: true,
          headerName: "Name",
          field: "name",
          cellRenderer: "name",
          cellRendererParams: {
            shouldShowPath() {
              return false;
            }
          },
          editable: false,
          flex: 1,
          comparator(a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          }
        },
        {
          headerName: "Size",
          // field: "currentVersion.upload.fileSize",
          cellRenderer: "fileSize",
          valueGetter(params) {
            if (isFolder(params.data)) {
              return params.data.size;
            }

            return params.data.currentVersion.upload.fileSize;
          }
        },
        {
          headerName: "",
          width: 65,
          resizable: false,
          pinned: "right",
          suppressSizeToFit: true,
          sortable: false,
          cellRenderer: "iconButton",
          cellRendererParams: {
            icon: "download",
            onClick(row) {
              vm.downloadDocument(row);
            }
          }
        }
      ],
      components: {
        vue: {
          name: DocumentsTableNameCellRenderer,
          fileSize: DocumentsTableFileSizeCellRendererVue
        }
      },
      listeners: {
        cellDoubleClicked: this.openDocument
      }
    };
  },
  computed: {
    showBulkActions() {
      return false;
    },
    tableBinds() {
      return {
        noRowsOverlayComponentParams: {
          title: "This folder is empty",
          message: "New documents will appear here if they are added"
        }
      };
    }
  },
  methods: {
    openDocument(row) {
      if (isFolder(row.data)) {
        this.startLoading();
        // Folder double clicked, so we're gonna drill down. We need to
        // calculate a custom path though, so that it's relative to the
        // share rather than the actual path of the folder, as they may
        // be very different.
        const { path } = row.data;
        const newPathPart = path.substring(path.lastIndexOf("/") + 1);
        if (this.breadcrumbPath.substring(-1) === "/") {
          this.breadcrumbPath += `${newPathPart}`;
        } else {
          this.breadcrumbPath += `/${newPathPart}`;
        }

        this.folder = row.data.path;
      }
    },
    downloadDocument(row) {
      this.docManager.downloadDocuments(row.data, this.folder);
    },
    getActionBarContent(h) {
      return [
        h(DocumentBreadcrumbs, {
          // props: { path: this.relativeFolderPath },
          props: { path: this.breadcrumbPath },
          on: { click: this.popToFolder }
        })
      ];
    },
    getFooter(h) {
      let fn =
        this.rowsSelected >= 1 ? this.downloadSelected : this.downloadAll;
      let label = this.rowsSelected >= 1 ? "Download selected" : "Download all";
      return h("div", { staticClass: "mt-8" }, [
        h(
          Components.AppButton,
          {
            props: { icon: "download", primary: true },
            on: { click: fn },
            staticClass: "ml-4"
          },
          label
        )
      ]);
    },
    downloadSelected() {
      this.docManager.downloadDocuments(
        this.gridApi().getSelectedRows(),
        this.folder
      );
    },
    downloadAll() {
      this.docManager.downloadDocuments(this.documents, this.folder);
    },
    popToFolder(folder) {
      this.startLoading();
      if (!folder || folder === "/") {
        // this.path.splice(0);
        this.folder = "/";
        this.breadcrumbPath = "/";
      } else {
        const popIndex = this.folder.lastIndexOf(folder) + folder.length;
        this.folder = this.folder.substring(0, popIndex);
        this.breadcrumbPath = folder;
      }
    }
  }
};
</script>
