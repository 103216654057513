<template lang="pug">
.share
  img.mx-auto.block(src="~@/assets/images/logo-light.png")
  app-panel.panel.mx-auto.mt-2.flex.flex-col
    app-header.text-center(margin="mb-4") Here are your files
    hr.border-grey-30
    .flex-1
      share-document-table
</template>

<script>
import ShareDocumentTable from "@/components/documents/share/ShareDocumentTable.vue";
export default {
  components: {
    ShareDocumentTable
  }
};
</script>
<style lang="postcss" scoped>
.panel {
  height: 50vh;
  min-height: 500px;
  width: 800px;
}
</style>
