import { apolloClient } from "@/vue-apollo";
import { mutationInputIDs } from "@/helpers/DocumentHelpers";
import DocumentURLQuery from "@/graphql/queries/document_management/DocumentShareURL.gql";
import { triggerDownload, triggerZipDownload } from "@/helpers/DownloadHelpers";

export default class DocumentShareManager {
  constructor(shareId) {
    this.shareId = shareId;
  }

  downloadDocuments(docsAndFolders, relativePath) {
    const input = mutationInputIDs(docsAndFolders);

    if (input.documents.length === 1 && !input.folders.length) {
      // Just a single download so we can download the file directly
      apolloClient
        .query({
          query: DocumentURLQuery,
          variables: {
            id: this.shareId,
            documentId: input.documents[0].id
          }
        })
        .then(({ data }) => {
          const {
            share: {
              document: {
                currentVersion: { url }
              }
            }
          } = data;

          triggerDownload(url);
        });
    } else {
      // Multiple documents, or a folder, so we need to download
      // as a zip
      triggerZipDownload(input, relativePath, { share: { id: this.shareId } });
    }
  }
}
